<template>
  <div class="about-container" v-loading="isLoading || !srcLoaded">
    <iframe
      v-if="src"
      :src="src"
      class="about-content"
      frameborder="0"
      @load="srcLoaded = true"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      srcLoaded: false,
    };
  },
  computed: mapState("about", {
    src: "data",
    isLoading: "isLoading",
  }),
  created() {
    this.$store.dispatch("about/fetchAbout");
  },
};
</script>
 
<style lang = "less" scoped>
.about-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.about-content {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}
</style>